import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

:root {
    --accent-100: #2E86AB;
    --primary-100: #FF5733;
	--text-100: #333333;
	--text-200: #5c5c5c;
    --text-300: #ffffff;
	--bg-100: #F4F4F4;
	--bg-200: #eaeaea;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
 height: 100vh;
 max-width: 100vw;
 margin: 0;
 background-color: var(--bg-100);
 color: var(--text-100);
}

`;

export default GlobalStyle;
