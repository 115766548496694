import React from "react";
import styled from "styled-components";
import butter from "../assets/butter.png";
import pinkSalt from "../assets/pink-salt.png";
import potatoes from "../assets/potatoes.png";
import onion from "../assets/onion.png";

const Products = () => {
	return (
		<Container>
			<GridContainer>
				<div className="content">
					<img src={pinkSalt} alt="pink salt" />
					<p>pink salt</p>
				</div>
				<div className="content">
					<img src={butter} alt="butter" />
					<p>desi ghee</p>
				</div>
				<div className="content">
					<img src={onion} alt="onions" />
					<p>onions</p>
				</div>
				<div className="content">
					<img src={potatoes} alt="potatoes" />
					<p>potatoes</p>
				</div>
			</GridContainer>
		</Container>
	);
};

export default Products;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-top: 8rem;
	align-items: center;
	height: 100vh;
`;

const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	gap: 2rem;

	p {
		align-self: center;
		text-align: center;
		background-color: var(--primary-100);
		color: var(--text-300);
		padding: 0.6rem;
		border-radius: 0.7rem;
		margin-top: 1rem;
		width: 30vw;
	}

	img {
		height: 10vh;
		width: 10vh;
	}

	.content {
		background-color: var(--bg-200);
		border-radius: 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 30vh;
		width: 40vw;
		margin-top: 1rem;
	}
`;
