import React from "react";
import GlobalStyle from "./Globalstyle";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Home";
import Products from "./Products";
import Navbar from "./Navbar";
import Footer from "./Footer";

const App = () => {
	return (
		<BrowserRouter>
			<Navbar />
			<GlobalStyle />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/products" element={<Products />} />
			</Routes>
			<Footer />
		</BrowserRouter>
	);
};

export default App;
