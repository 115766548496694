import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import onlineShop from "../assets/online-shop.png";

const Home = () => {
	const navigate = useNavigate();

	return (
		<div>
			<Welcome>
				<TextContainer>
					<p>
						Welcome to Al-Shafi Industries, your trusted source for quality
						groceries. Discover a diverse selection of fresh produce, pantry
						essentials, and more, all conveniently available for you. Experience
						the ease of shopping with us as we bring the finest ingredients
						right to your doorstep.
					</p>
				</TextContainer>
				<Image src={onlineShop} alt="ecom shopping icon" />
				<ButtonContainer>
					<Button onClick={() => navigate("/products")}>
						View our products
					</Button>
				</ButtonContainer>
			</Welcome>
		</div>
	);
};

export default Home;

const Welcome = styled.div`
	font-family: "Sarabun", sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 2rem;
	height: 100vh;
	margin-top: 6rem;
`;

const TextContainer = styled.div`
	width: 80vw;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	p {
		font-size: 1.2rem;
		line-height: 1.4;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const Button = styled.button`
	background-color: var(--primary-100);
	color: var(--text-300);
	padding: 1rem;
	border-radius: 0.6rem;
	border: none;
	width: 70vw;
	margin-top: 4rem;
	font-size: 1rem;
`;

const Image = styled.img`
	height: 15vh;
	align-self: flex-end;
`;
