import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Navbar = () => {
	const navigate = useNavigate();
	return (
		<Container>
			<Title onClick={() => navigate("/")}>Al-Shafi Industries</Title>
		</Container>
	);
};

export default Navbar;

const Container = styled.div`
	background-color: var(--bg-200);
	height: 5vh;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Title = styled.h3`
	font-weight: 500;
	font-family: "Work Sans", sans-serif;
`;
