import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Footer = () => {
	const navigate = useNavigate();
	return (
		<div>
			<Main>
				<FooterContainer>
					<div>
						<h4>AL SHAFI INDUSTRIES</h4>
					</div>
					<div className="buttons-container">
						<button onClick={() => navigate("/products")}>Products</button>
					</div>
				</FooterContainer>
			</Main>
		</div>
	);
};

export default Footer;

const Main = styled.div`
	background-color: var(--bg-100);
	color: var(--text-100);
	display: flex;
	position: relative;
	width: 100%;
	height: 10vh;
	bottom: 0;
	z-index: 10;

	.buttons-container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
	}

	button {
		border: none;
		background-color: transparent;
		margin: 1rem;
		font-weight: 100;
		font-size: 0.8rem;

		&:hover {
			background-color: grey;
			border-radius: 0.5rem;
			padding: 0.2rem 0;
		}
	}
`;

const FooterContainer = styled.div`
	width: 80%;
	background-color: var(--bg-100);
	display: block;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin: 1rem auto;

	h4 {
		font-weight: 600;
		text-align: center;
		font-size: 0.9rem;
	}
`;
